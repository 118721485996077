import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/85/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>&nbsp;VISIBLY CLEAR<sup>®&nbsp;</sup>
                    Pore &amp; Shine Pleťová maska do sprchy
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/Visibly-Clear-PoreShine-Maska-do-sprchy6.jpg"
                      alt="Visibly Clear PoreShine Maska do sprchy6"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>&nbsp;VISIBLY CLEAR
                      <sup>®&nbsp;</sup>Pore &amp; Shine Pleťová maska do sprchy
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p className="TableParagraph">
                        Získejte <strong>krásnou a hladkou pleť</strong> bez
                        lesku již za <strong>60 sekund</strong> díky průlomové
                        masce, která účinkuje ve spojení s horkou sprchou.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Maska má klinicky ověřené složení, které:
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        1) <strong>Redukuje lesk:</strong> napomáhá odstranit
                        nadbytečný kožní maz, aniž by pleť vysušovala.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        2) <strong>Napomáhá čistit ucpané póry:</strong> čistí
                        do hloubky a uvolňuje ucpané póry. Pleť zůstane matná a
                        vypnutá.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Svěží vůně mandarinky a limetky dodá Vaší pleti
                        povzbuzující a svěží pocit.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte na navhlčený obličej dvakrát týdně. Nechte
                      působit po dobu 60 sekund v horké sprše a poté krouživými
                      pohyby jemně smývejte.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>150 ml</dd>
                  </dl>
                </div>
                <div id="fb_40"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/103/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-TMW-400ml-002f.jpg"
                      alt="NTG SkinDetox TMW 400ml 002f"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/106/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-Peeling-150ml-bez-stinu2.jpg"
                      alt="NTG SkinDetox Peeling 150ml bez stinu2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Pleťový peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/107/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-krem-2v1-50-ml2.jpg"
                      alt="NTG SkinDetox krem 2v1 50 ml2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>
                        <sup>&nbsp; </sup>Skin Detox Hydratační krém 2v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/111/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Cellular-Boost-SERUM-Crtn-30-3D2.jpg"
                      alt="NTG Cellular Boost SERUM Crtn 30 3D2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA® Cellular Boost Koncentrované sérum proti
                        vráskám
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/113/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Cellular-Boost-DENNI-krem-Crtn-50-3D2.jpg"
                      alt="NTG Cellular Boost DENNI krem Crtn 50 3D2"
                    />
                    <span className="txt">
                      <p className="TableParagraph">
                        NEUTROGENA® Cellular Boost Omlazující denní krém s SPF
                        20
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
